
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {ElNotification} from "element-plus/es";
//import Loading from "@/components/Loading.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  total_pages: number;
}

export default defineComponent({
  name: "Offices",
  components: {
    //Loading,
    CardBasicNew,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const dataList = ref([]);
    const filtered = ref([]);

    const company_id = ref([]);
    const users = ref([]);
    const addresses = ref([]);
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const viewType = ref(user.user_preferences.view_type_items);
    const companies = ref(user.companies.map(c => c.id));
    const tableButtons = ref([]);
    const originalData = ref([]);
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);

    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: parseInt(user.user_preferences.items_per_page),
      total: 0,
      total_pages: 1
    });

    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("iremarks"), key: "remarks" },
      { column: t("icompany"), key: "company" },
      { column: t("iismain"), key: "is_main", type: "check" },
    ]);

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: create") !== -1
      );
    });
    const canUpdate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: update") !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: delete") !== -1
      );
    });
    const canShow = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: show") !== -1
      );
    });
    const deleteElement = () => {
      const item = filtered.value.find(
        (x: any) => x.id === deleteId.value
      ) as any;
      if (!item.can_it_be_deleted) {
        ElNotification({
          title: "Error",
          message: t("rofficedelete"),
          type: "error",
        });
        return;
      }
      store.commit("setLoadingStatus", true);
      ApiService.delete(`/api/offices/${deleteId.value}`).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          iname: element.name,
          icompany: element.company.name,
          iismain: element.is_main === 0 ? t("ino") : t("iyes"),
          user: element.users.length,
        };
        return element;
      });
    };

    const getItems = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/offices`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: search.value,
          users: [user.id],
        },
      }).then(({ data }) => {
        originalData.value = data.offices;
        dataList.value =
          viewType.value === "card"
            ? createFieldItems(originalData.value)
            : createTableItems(originalData.value);
        filtered.value = dataList.value;
        pagination.value.total = data.pagination.total;
        pagination.value.total_pages = Math.ceil(pagination.value.total/pagination.value.rowsPerPage)
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        store.commit("setLoadingStatus", false);
      });
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("offices", [{ route: "", label: "offices" }]);
      getItems();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/offices`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: search.value,
          users: [user.id],
        },
      }).then(({ data }) => {
        originalData.value = data.offices;
        dataList.value = createFieldItems(data.offices);
        pagination.value.total = data.pagination.total;
        pagination.value.total_pages = Math.ceil(pagination.value.total/pagination.value.rowsPerPage)
        filtered.value =
          viewType.value === "card"
            ? data.offices
            : createTableItems(data.offices);
        store.commit("setLoadingStatus", false);
      });
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      if (!filtered.value.length) {
        getItems();
      }
    };

    const createElement = () => {
      router.push({ name: "officesCreate" });
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        const usersName = element.users.map((x) => x.name);
        result.push({
          id: element.id,
          can_it_be_deleted: element.can_it_be_deleted,
          name: element.name,
          is_main: element.is_main === 1,
          company: element.company.name,
          user: usersName
            .filter((x, index) => {
              return usersName.indexOf(x) === index;
            })
            .join(", "),
        });
      });
      return result;
    };

    watch(
      () => viewType.value,
      (first) => {
        if (first) {
          filtered.value =
            first === "card"
              ? createFieldItems(originalData.value)
              : createTableItems(originalData.value);
        }
      }
    );

    const seeDetails = (id) => {
      router.push({ name: "officeUpdate", params: { id: id } });
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push({ name: "officeUpdate", params: { id: param1 } });
      } else if (param2 === "REMOVE") {
        deleteDialog(param3 ? param3 : param1);
      }
    };

    return {
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      users,
      addresses,
      company_id,
      showDelete,
      deleteDialog,
      deleteElement,
      seeDetails,
      onSearch,
      serverSearch,
      createElement,
      actionsButton,
      currentPageChange,
      setItemsPerPage,
      getItems,
      canShow,
      canCreate,
      canUpdate,
      canDelete,
      user,
    };
  },
});
